import dayjs from "dayjs"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React, { useState } from "react"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import isScrolling from "../utilities/isScrolling"

const Blog = ({ data, location }) => {
  const { viewPortWidth, isAnnouncementActive } = React.useContext(StoreContext)

  const setFooter =
    data.allWpPage.edges[0]?.node.headerAndFooter.pageFooterStyle

  const overviewBlogPage = data.allWpPage.edges[0]?.node
  const disclaimer =
    data?.wp?.overviewBlog?.overviewBlog?.blogFooterContent?.content

  const posts = data.allWpPost.edges.sort((a, b) => {
    const dateA = new Date(a.node.dateGmt).getTime()
    const dateB = new Date(b.node.dateGmt).getTime()
    return dateA > dateB ? -1 : 1
  })

  const categories = data.allWpCategory

  const [myArr, setMyArr] = useState([])

  const [filter, setFilter] = useState("")

  const handleChange = e => {
    setFilter(e.target.value)
  }

  // State for the list results (Need to apply to newsroom page)
  const [maxRange, setMaxRange] = useState(9)

  const loadMore = React.useCallback(() => {
    setMaxRange(prevRange => prevRange + 9)
  }, [])

  const featuredBlog = data.wp?.overviewBlog?.overviewBlog
  // const blogHeaderContent = featuredBlog.blogHeaderContent
  const featuredBlogPost = featuredBlog.featuredBlogPosts[0] // max value of 1
  const featuerdPostId = featuredBlogPost?.id
  const featuredPostsSetting = featuredBlog.featuredPostsSetting
  let featuredBlogPostImg = ""

  if (featuredBlogPost?.featuredImage?.node.localFile.childImageSharp) {
    featuredBlogPostImg = getImage(
      featuredBlogPost?.featuredImage?.node.localFile.childImageSharp
        .gatsbyImageData
    )
  }

  const filteredData = posts.filter(item => {
    if (
      item.node.title.toLowerCase().includes(filter) &&
      item.node.id !== featuerdPostId
    ) {
      return item
    }
  })

  React.useEffect(() => {
    isScrolling()
  })

  return (
    <Layout>
      <Seo pageData={overviewBlogPage} location={location} />

      <div className="blog wp-embed-responsive is-blog has-sidebar customize-support js">
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <div className="Archive">
              <div className="Wrap">
                <div className="Blog-header">
                  <div className="Grid with-2 has-xSmallGaps">
                    <div className="Blog-header-home is-2--small">
                      <h1>
                        <Link to="/blog">Blog</Link>
                      </h1>
                    </div>

                    <div className="Blog-header-search hide-small">
                      <input
                        type="text"
                        name="s"
                        id="s"
                        aria-placeholder="Search the blog"
                        placeholder="Search the blog"
                        value={filter}
                        onChange={e => handleChange(e)}
                      />
                      <button
                        type="submit"
                        title="Search"
                        aria-label="Search"
                        role="presentation"
                      >
                        <span>Search</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="Blog-topics">
                  <div className="Blog-topics-list">
                    <div>
                      <ul>
                        <li>
                          <h3 className="Blog-topics-label">Show Topic</h3>
                        </li>
                        <li className="Blog-topics-separator hide-small">|</li>

                        {categories.edges.map(item => {
                          const name = item.node.name
                          const id = item.node.id
                          return (
                            <li key={id}>
                              <a href="#" onClick={() => setMyArr(name)}>
                                {name}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                {featuredPostsSetting && (
                  <div className="Blog-featured">
                    <article className="Content post type-post status-publish format-standard has-post-thumbnail hentry category-compliance category-news-updates">
                      {featuredBlogPost && (
                        <Link to={featuredBlogPost.uri}>
                          <GatsbyImage
                            image={featuredBlogPostImg}
                            className="Content-thumbnail featured"
                          />
                          <div className="Content-text">
                            <div className="Content-kicker">
                              <span>Featured Blog</span>
                              <time
                                datetime={featuredBlogPost.date}
                                className="ContentDate"
                              >
                                {dayjs(featuredBlogPost.date).format(
                                  "MMMM DD, YYYY"
                                )}
                              </time>
                            </div>
                            <header className="Content-header">
                              <h2 className="Content-title">
                                {featuredBlogPost.title}
                              </h2>
                            </header>
                            <div
                              className="Content-excerpt"
                              dangerouslySetInnerHTML={{
                                __html: featuredBlogPost.excerpt,
                              }}
                            ></div>
                            <div className="Content-footer">Read Now</div>
                          </div>
                        </Link>
                      )}
                    </article>
                  </div>
                )}

                <div className="Blog">
                  <div className="Blog-grid">
                    {/* TODO: make this into a component */}
                    {filteredData
                      .filter(tmp => {
                        const active = tmp

                        let current = []

                        active.node.categories.nodes.map(cat => {
                          current = [...current, cat.name]
                        })

                        if (
                          current.includes(myArr) ||
                          myArr === undefined ||
                          myArr.length === 0
                        ) {
                          return tmp
                        }
                      })
                      .slice(0, maxRange)
                      .map(post => {
                        let featImg = post.node.featuredImage
                        let featImgAlt = ""

                        if (
                          featImg?.node.localFile?.childImageSharp
                            ?.gatsbyImageData
                        ) {
                          featImg = getImage(
                            post.node.featuredImage.node.localFile
                          )
                          featImgAlt = post.node.featuredImage.node.altText
                        }

                        return (
                          <div key={post.node.id}>
                            <div>
                              <article className="Content post type-post status-publish hentry has-post-thumbnail">
                                <Link
                                  to={`/blog/${post.node.slug}/`}
                                  target="_blank"
                                >
                                  <div className="Content-thumbnail">
                                    <GatsbyImage
                                      image={featImg}
                                      alt={featImgAlt}
                                    />
                                  </div>

                                  <div className="Content-text">
                                    <header className="Content-header">
                                      <time
                                        className="Content-date"
                                        dateTime={post.node.date}
                                      >
                                        {dayjs(post.node.date).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </time>
                                      <h3 className="Content-title">
                                        {post.node.title}
                                      </h3>
                                    </header>
                                    <div className="Content-footer">
                                      Read Now
                                    </div>
                                  </div>
                                </Link>
                              </article>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  {filteredData.length === 0 && (
                    <div className="Resources-empty">
                      <section className="no-results not-found">
                        <header className="page-header">
                          <h1 className="page-title">Nothing Found</h1>
                        </header>{" "}
                        <div className="page-content"></div>
                      </section>
                    </div>
                  )}

                  <button
                    className={`load-more ${
                      maxRange > filteredData.length || filteredData.length < 9
                        ? "no-more"
                        : ""
                    }`}
                    onClick={loadMore}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>

          {parse(disclaimer)}

          {setFooter === null && <Footer />}

          {setFooter === "default" && <Footer />}

          {setFooter === "landing-page" && <FooterLandingPage />}

          {setFooter === "get-started" && <FooterMinimal />}
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  {
    allWpPost {
      edges {
        node {
          id
          title
          slug
          date
          dateGmt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 176, width: 396, placeholder: BLURRED)
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    allWpCategory {
      edges {
        node {
          id
          name
        }
      }
    }
    allWpPage(filter: { databaseId: { eq: 53 } }) {
      edges {
        node {
          id
          title
          headerAndFooter {
            pageFooterStyle
            pageHeaderStyle
          }
          databaseId
          seo {
            title
            metaDesc
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphPublishedTime
            opengraphModifiedTime
            opengraphSiteName
            opengraphType
            readingTime
          }
          richSnippetSchemaJson
        }
      }
    }
    wp {
      overviewBlog {
        overviewBlog {
          blogHeaderContent {
            ... on WpPage {
              id
            }
          }
          blogFooterContent {
            ... on WpPage {
              id
              content
            }
          }
          featuredBlogPosts {
            ... on WpPost {
              id
              date
              excerpt
              title
              uri
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
          }
          featuredPostsSetting
        }
      }
    }
  }
`
